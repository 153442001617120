import "@/styles/globals.css";
import type { AppProps } from "next/app";
import { SessionProvider } from "next-auth/react";
import { DarkThemeContextProvider } from "@/contexts/DarkTheme";
import { LoadingContextProvider } from "@/contexts/Loading";
import { TickerContextProvider } from "@/contexts/TickerContext";
import { VfundsContextProvider } from "@/contexts/VfundsContext";
import { PortfolioContextProvider } from "@/contexts/PortfolioContext";
import { ModalContextProvider } from "@/contexts/ModalContext";
import { RecoilRoot } from "recoil";
import TopProgressBar from "@/components/TopProgressBar";
import { appWithTranslation } from "next-i18next";
import { Toaster } from "@/components/ui/toaster";
import { TooltipProvider } from "@/components/ui/tooltip";
import Hotjar from "@hotjar/browser";
import Head from "next/head";
import GoogleAnalytics from "@/components/GA";
import { ConnectFormContextProvider } from "@/contexts/ConnectFormContext";
import { SubscriptionContextProvider } from "@/contexts/SubscriptionContext";
import { AIAssistantContextProvider } from "@/contexts/AIAssistantContext";
import { BacktestTourContextProvider } from "@/contexts/BacktestTourContext";
import { SidebarContextProvider } from "@/contexts/SidebarContext";
import { TechnicalRequestContextProvider } from "@/contexts/TechnicalRequestContext";

if (typeof window !== "undefined") {
  // checks that we are client-side
  const siteId = 3751159;
  const hotjarVersion = 6;

  Hotjar.init(siteId, hotjarVersion);
}
const App = ({ Component, pageProps: { session, ...pageProps } }: AppProps) => {
  return (
    <>
      <Head>
        <GoogleAnalytics GA_TRACKING_ID={"G-NRPKERR6KT"} />
        <meta
          name="viewport"
          content="width=device-width,initial-scale=1"
          key="viewport"
        />
      </Head>

      <SessionProvider session={session}>
        <Toaster />
        <DarkThemeContextProvider>
          {/* <BacktestTourContextProvider> */}
          <SidebarContextProvider>
            <TechnicalRequestContextProvider>
              <AIAssistantContextProvider>
                <ConnectFormContextProvider>
                  <SubscriptionContextProvider>
                    <LoadingContextProvider>
                      <TickerContextProvider>
                        <VfundsContextProvider>
                          <PortfolioContextProvider>
                            <ModalContextProvider>
                              <RecoilRoot>
                                <TooltipProvider>
                                  <TopProgressBar />
                                  <Component {...pageProps} />
                                </TooltipProvider>
                              </RecoilRoot>
                            </ModalContextProvider>
                          </PortfolioContextProvider>
                        </VfundsContextProvider>
                      </TickerContextProvider>
                    </LoadingContextProvider>
                  </SubscriptionContextProvider>
                </ConnectFormContextProvider>
              </AIAssistantContextProvider>
            </TechnicalRequestContextProvider>
          </SidebarContextProvider>
          {/* </BacktestTourContextProvider> */}
        </DarkThemeContextProvider>
      </SessionProvider>
    </>
  );
};
export default appWithTranslation(App);
